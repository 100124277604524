import { SVGIcon } from '@instructure/ui-svg-images'

function IconClassroomLine(props) {
  return (
      <SVGIcon name={"IconCurriculumMap"} viewBox="0 0 15 16" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.33334 15.0588H13.1868V0.941162H5.33334V15.0588ZM4.66668 15.0588V0.941162H1.88386V1.88234V2.82352V3.86927H2.35481C2.61478 3.86927 2.82577 4.08009 2.82577 4.33986C2.82577 4.59962 2.61478 4.81044 2.35481 4.81044H1.88386V7.63397H2.35481C2.61478 7.63397 2.82577 7.8448 2.82577 8.10456C2.82577 8.36433 2.61478 8.57515 2.35481 8.57515H1.88386V11.3987H2.35481C2.61478 11.3987 2.82577 11.6095 2.82577 11.8693C2.82577 12.129 2.61478 12.3399 2.35481 12.3399H1.88386V15.0588H4.66668ZM0.941912 1.88235V0H14.1287V16H0.941912V12.3399H0.470956C0.210988 12.3399 0 12.129 0 11.8693C0 11.6095 0.210988 11.3987 0.470956 11.3987H0.941912V8.57516H0.470956C0.210988 8.57516 0 8.36434 0 8.10458C0 7.84481 0.210988 7.63399 0.470956 7.63399H0.941912V4.81046H0.470956C0.210988 4.81046 0 4.59963 0 4.33987C0 4.0801 0.210988 3.86928 0.470956 3.86928H0.941912V2.82353V1.88235Z"/>
      </SVGIcon>
  )
}

export {IconClassroomLine}
