import { SVGIcon } from '@instructure/ui-svg-images'

function IconDropbox(props) {
  return (
      <SVGIcon name={"IconDropbox"} viewBox="0 0 48 40" {...props}>
        <path d="M11.8078 0L0 7.50036L11.8078 15.0005L23.6157 7.50036L11.8078 0Z" fill="#0061fe"/>
        <path d="M35.4251 0L23.6173 7.50036L35.4251 15.0005L47.233 7.50036L35.4251 0Z" fill="#0061fe"/>
        <path d="M0 22.5025L11.8078 30.0027L23.6159 22.5025L11.8078 15.0024L0 22.5025Z"  fill="#0061fe"/>
        <path d="M35.4223 15.0024L23.6144 22.5025L35.4223 30.0027L47.2301 22.5025L35.4223 15.0024Z" fill="#0061fe"/>
        <path d="M11.8086 32.4998L23.6165 40L35.4243 32.4998L23.6165 25.0003L11.8086 32.4998Z" fill="#0061fe"/>
      </SVGIcon>
  )
}

export {IconDropbox}
