import { SVGIcon } from '@instructure/ui-svg-images'
import type { SVGIconProps } from '@instructure/ui-svg-images'

const IconVersioningSolid = (props: SVGIconProps) => (
  <SVGIcon name={'IconVersioningSolid'} viewBox="0 0 18 18" {...props}>
    <path d="M6.28223 0.420553C11.0135 -1.07748 16.0814 1.5544 17.5784 6.28355C19.0754 11.0137 16.4467 16.0806 11.7165 17.5786C10.8155 17.8645 9.90293 18 9.00517 18C5.18649 18 1.63249 15.547 0.420298 11.7167L1.42922 11.397C2.75046 15.5703 7.22128 17.8899 11.3957 16.5697C15.5711 15.2485 17.8907 10.7777 16.5695 6.60327C15.2482 2.42888 10.7753 0.110359 6.60301 1.43054C4.59045 2.06787 2.94738 3.45262 1.97868 5.29473H4.23474V6.35342H0V2.11868H1.05868V4.77069C2.15972 2.69884 4.01347 1.1394 6.28223 0.420553Z" />
    <path d="M6.81168 4.23594L7.47336 9.52936H10.5255L11.1872 4.23594H6.81168Z" />
    <path d="M7.41132 13.7641H10.5874V10.588H7.41132V13.7641Z" />
  </SVGIcon>
)

export { IconVersioningSolid }
