import { SVGIcon } from '@instructure/ui-svg-images'

function IconSpaceport(props) {
  return (
      <SVGIcon name={"IconSpacePort"} viewBox="0 0 125 120" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M84.0146 90.6977H103.55V28.6046H84.0146V90.6977ZM82.6193 92.093H104.945V27.2093H82.6193V92.093Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M103.903 49.479L82.8237 28.4002L83.8102 27.4137L104.889 48.4925L103.903 49.479Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M83.8103 49.479L82.8238 48.4925L103.903 27.4137L104.889 28.4002L83.8103 49.479Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M103.903 71.1784L82.8237 50.0996L83.8102 49.1131L104.889 70.1919L103.903 71.1784Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M83.8103 71.1784L82.8238 70.1919L103.903 49.1131L104.889 50.0996L83.8103 71.1784Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M103.903 91.6883L82.8237 70.6095L83.8102 69.623L104.889 90.7018L103.903 91.6883Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M83.8103 91.6883L82.8238 90.7018L103.903 69.623L104.889 70.6095L83.8103 91.6883Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M83.317 50.3065H104.247V48.9112H83.317V50.3065Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M83.317 71.2368H104.247V69.8414H83.317V71.2368Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M59.7237 32.167H83.317V30.7716H59.7237V32.167Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M60.2226 35.5814H83.317V34.186H60.2226V35.5814Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.3994 63.3841C21.4657 63.3841 13.9852 69.6024 12.0492 77.9466C11.542 80.1317 13.2101 82.2213 15.4538 82.2213H45.345C47.588 82.2213 49.2562 80.1317 48.7497 77.9466C46.8129 69.6024 39.3324 63.3841 30.3994 63.3841Z" fill="#C0C6CB"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.3994 64.0817C21.9108 64.0817 14.6445 69.848 12.7294 78.1036C12.5362 78.9345 12.7308 79.7948 13.2631 80.4645C13.7969 81.1378 14.5964 81.5236 15.4538 81.5236H45.345C46.2024 81.5236 47.0013 81.1378 47.5357 80.4645C48.0673 79.7948 48.2627 78.9345 48.0694 78.1036C46.1536 69.848 38.8873 64.0817 30.3994 64.0817ZM45.345 82.919H15.4538C14.1673 82.919 12.9715 82.3406 12.1699 81.3332C11.3717 80.3278 11.0801 79.0364 11.3703 77.7883C13.4327 68.8964 21.2578 62.6864 30.3994 62.6864C39.5404 62.6864 47.3655 68.8964 49.4285 77.7883C49.718 79.0364 49.4264 80.3278 48.6283 81.3339C47.8266 82.3413 46.6308 82.919 45.345 82.919Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.4227 63.3841C44.489 63.3841 37.0085 69.6024 35.0724 77.9466C34.5652 80.1317 36.2334 82.2213 38.4771 82.2213H68.3683C70.6113 82.2213 72.2794 80.1317 71.7729 77.9466C69.8362 69.6024 62.3557 63.3841 53.4227 63.3841Z" fill="#C0C6CB"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.4227 64.0817C44.9341 64.0817 37.6678 69.848 35.7527 78.1036C35.5594 78.9345 35.7541 79.7948 36.2864 80.4645C36.8201 81.1378 37.6197 81.5236 38.4771 81.5236H68.3683C69.2257 81.5236 70.0245 81.1378 70.559 80.4645C71.0906 79.7948 71.2859 78.9345 71.0927 78.1036C69.1769 69.848 61.9106 64.0817 53.4227 64.0817ZM68.3682 82.919H38.4771C37.1906 82.919 35.9948 82.3406 35.1931 81.3332C34.395 80.3278 34.1034 79.0364 34.3936 77.7883C36.4559 68.8964 44.281 62.6864 53.4227 62.6864C62.5636 62.6864 70.3887 68.8964 72.4517 77.7883C72.7413 79.0364 72.4496 80.3278 71.6515 81.3339C70.8499 82.3413 69.6541 82.919 68.3682 82.919Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M57.6149 82.2213C59.9325 73.7355 61.3042 63.2627 61.3042 51.9248C61.3042 23.6327 52.7793 0.698029 42.2625 0.698029C31.7465 0.698029 23.2209 23.6327 23.2209 51.9248C23.2209 63.2627 24.5932 73.7355 26.9109 82.2213H57.6149Z" fill="#E5E7E9"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="22" y="0" width="41" height="83">
          <path fillRule="evenodd" clipRule="evenodd" d="M22.5231 0H62.0017V82.919H22.5231V0Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
          <path fillRule="evenodd" clipRule="evenodd" d="M27.4452 81.5236H57.081C59.3875 72.855 60.6064 62.6348 60.6064 51.9248C60.6064 24.0631 52.3773 1.39498 42.2624 1.39498C32.1475 1.39498 23.9184 24.0631 23.9184 51.9248C23.9184 62.6334 25.1373 72.8536 27.4452 81.5236ZM58.1477 82.9189H26.3784L26.2382 82.4054C23.8075 73.508 22.5231 62.9682 22.5231 51.9248C22.5231 22.808 31.1938 -0.000366211 42.2624 -0.000366211C53.3317 -0.000366211 62.0017 22.808 62.0017 51.9248C62.0017 62.9696 60.7173 73.5101 58.288 82.4054L58.1477 82.9189Z" fill="#243038"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M51.3358 90.9889H33.19L30.3993 82.2212H54.1265L51.3358 90.9889Z" fill="#D0D4D8"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M33.7004 90.2909H50.8255L53.1718 82.9192H31.3541L33.7004 90.2909ZM51.8462 91.6862H32.6797L29.4446 81.5239H55.0813L51.8462 91.6862Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M53.251 50.5292C53.251 56.5983 48.3317 61.5176 42.2626 61.5176C36.1942 61.5176 31.2742 56.5983 31.2742 50.5292C31.2742 44.4608 36.1942 39.5408 42.2626 39.5408C48.3317 39.5408 53.251 44.4608 53.251 50.5292Z" fill="#C0C6CB"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2626 40.2388C36.5884 40.2388 31.9719 44.8554 31.9719 50.5295C31.9719 56.2037 36.5884 60.8202 42.2626 60.8202C47.9368 60.8202 52.5533 56.2037 52.5533 50.5295C52.5533 44.8554 47.9368 40.2388 42.2626 40.2388ZM42.2626 62.2156C35.8189 62.2156 30.5766 56.9733 30.5766 50.5296C30.5766 44.0858 35.8189 38.8435 42.2626 38.8435C48.7063 38.8435 53.9486 44.0858 53.9486 50.5296C53.9486 56.9733 48.7063 62.2156 42.2626 62.2156Z" fill="#243038"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M49.937 50.5292C49.937 54.7676 46.501 58.2036 42.2626 58.2036C38.0242 58.2036 34.5882 54.7676 34.5882 50.5292C34.5882 46.2908 38.0242 42.8548 42.2626 42.8548C46.501 42.8548 49.937 46.2908 49.937 50.5292Z" fill="#9AD6F5"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.2626 43.5528C38.4156 43.5528 35.2859 46.6826 35.2859 50.5296C35.2859 54.3765 38.4156 57.5063 42.2626 57.5063C46.1096 57.5063 49.2393 54.3765 49.2393 50.5296C49.2393 46.6826 46.1096 43.5528 42.2626 43.5528ZM42.2626 58.9017C37.6461 58.9017 33.8905 55.1461 33.8905 50.5296C33.8905 45.9131 37.6461 42.1575 42.2626 42.1575C46.8791 42.1575 50.6347 45.9131 50.6347 50.5296C50.6347 55.1461 46.8791 58.9017 42.2626 58.9017Z" fill="#243038"/>
        <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="125" height="120">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 120H124.48V0H0V120Z" fill="white"/>
        </mask>
        <g mask="url(#mask1)">
          <path fillRule="evenodd" clipRule="evenodd" d="M26.723 23.0121H57.803V21.6167H26.723V23.0121Z" fill="#243038"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M118.201 98.3724H6.57286L9.18844 91.8338C9.29379 91.5694 9.55123 91.3956 9.83588 91.3956H114.938C115.222 91.3956 115.48 91.5694 115.585 91.8338L118.201 98.3724Z" fill="#D0D4D8"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M7.60304 97.6744H117.17L114.937 92.093H9.8356L7.60304 97.6744ZM119.232 99.0698H5.54211L8.54072 91.5754C8.75281 91.0423 9.26142 90.6977 9.8356 90.6977H114.938C115.512 90.6977 116.02 91.0423 116.233 91.5761L119.232 99.0698Z" fill="#243038"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M6.57278 106.047H118.201V98.3721H6.57278V106.047Z" fill="#E5E7E9"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M7.27047 105.349H117.503V99.0698H7.27047V105.349ZM5.87512 106.744H118.898V97.6744H5.87512V106.744Z" fill="#243038"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.36357 119.303H1.39612C0.884029 119.303 0.546354 118.769 0.766122 118.306L6.57287 106.047H27.5031L9.36357 119.303Z" fill="#C0C6CB"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M7.01434 106.744L1.39597 118.605H9.13597L25.366 106.744H7.01434ZM9.59086 120H1.39597C0.915276 120 0.474346 119.757 0.216904 119.35C-0.0398405 118.944 -0.0705382 118.442 0.135973 118.007L6.13109 105.349H29.6406L9.59086 120Z" fill="#243038"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M115.116 119.303H123.084C123.595 119.303 123.934 118.769 123.714 118.306L117.907 106.047H96.9769L115.116 119.303Z" fill="#C0C6CB"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M115.344 118.605H123.084L117.466 106.744H99.114L115.344 118.605ZM123.084 120H114.889L94.8394 105.349H118.349L124.344 118.008C124.551 118.442 124.52 118.944 124.262 119.35C124.006 119.757 123.565 120 123.084 120Z" fill="#243038"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M58.3956 119.019C58.5268 119.197 58.7354 119.302 58.9573 119.302H66.5724C67.0838 119.302 77.4421 106.047 77.4421 106.047H48.8375L58.3956 119.019Z" fill="#C0C6CB"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M58.9571 118.605H66.3482C67.3724 117.632 72.4236 111.313 76.0089 106.744H50.218L58.9571 118.605ZM58.957 118.605L58.3954 119.019L58.957 118.605ZM66.5722 120H58.9571C58.5154 120 58.0961 119.788 57.8345 119.433L47.4573 105.349H78.8729L77.9917 106.476C67.424 120 66.9915 120 66.5722 120Z" fill="#243038"/>
        </g>
      </SVGIcon>
  )
}

export {IconSpaceport}