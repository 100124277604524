import { SVGIcon } from '@instructure/ui-svg-images'
import type { SVGIconProps } from '@instructure/ui-svg-images'

const IconReservedLine = (props: SVGIconProps) => (
  <SVGIcon name={'IconReservedLine'} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1303 15.0588C12.6498 15.0588 13.0715 14.6362 13.0715 14.1176V8.47059H12.1303H3.65969H2.71852V14.1176C2.71852 14.6362 3.14111 15.0588 3.65969 15.0588H12.1303ZM12.1303 4.23529V7.52941H14.0126V14.1176C14.0126 15.1558 13.1684 16 12.1303 16H3.6597C2.62158 16 1.77734 15.1558 1.77734 14.1176V7.52941H3.6597V4.23529C3.6597 1.90024 5.55993 0 7.89499 0C10.2301 0 12.1303 1.90024 12.1303 4.23529ZM7.89501 0.941177C6.07853 0.941177 4.60089 2.41882 4.60089 4.23529V7.52941H11.1891V4.23529C11.1891 2.41882 9.71148 0.941177 7.89501 0.941177ZM8.36558 13.1765H7.42441V10.3529H8.36558V13.1765Z"
      fill="#6B7780"
    />
  </SVGIcon>
)

export { IconReservedLine }
