import { SVGIcon } from '@instructure/ui-svg-images'
import type { SVGIconProps } from '@instructure/ui-svg-images'

const IconPrintableLine = (props: SVGIconProps) => (
  <SVGIcon
    name={'IconPrintableLine'}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2941 4.70588H4.70589V0.941177H11.2941V4.70588ZM12.2353 4.70588H14.5882C15.3666 4.70588 16 5.33929 16 6.11765V12.7059C16 13.4842 15.3666 14.1176 14.5882 14.1176H13.1765V13.1765H14.5882C14.8471 13.1765 15.0588 12.9647 15.0588 12.7059V6.11765C15.0588 5.85882 14.8471 5.64706 14.5882 5.64706H1.41176C1.152 5.64706 0.941177 5.85882 0.941177 6.11765V12.7059C0.941177 12.9647 1.152 13.1765 1.41176 13.1765H2.82353V14.1176H1.41176C0.633412 14.1176 0 13.4842 0 12.7059V6.11765C0 5.33929 0.633412 4.70588 1.41176 4.70588H3.76471V0H12.2353V4.70588ZM13.1765 8.47059H11.2941V7.52941H13.1765V8.47059ZM13.1765 11.2941V10.3529H2.82354V11.2941H3.76472V16H12.2353V11.2941H13.1765ZM11.2941 15.0588H4.70589V11.2941H11.2941V15.0588Z"
      fill="#6B7780"
    />
  </SVGIcon>
)

export { IconPrintableLine }
