import { SVGIcon } from '@instructure/ui-svg-images'
import type { SVGIconProps } from '@instructure/ui-svg-images'

const IconVersioningLine = (props: SVGIconProps) => (
  <SVGIcon name={'IconVersioningLine'} viewBox="0 0 18 18" {...props}>
    <path d="M6.28359 0.42146C11.014 -1.07877 16.0811 1.55431 17.5792 6.28474C19.0774 11.0152 16.4475 16.0823 11.716 17.5804C10.816 17.8662 9.90341 18.0018 9.0056 18.0018C5.18569 18.0018 1.63257 15.5487 0.420318 11.7182L1.42929 11.3984C2.75165 15.572 7.22269 17.8895 11.3973 16.5714C15.5708 15.2501 17.8916 10.7791 16.5703 6.60447C15.2479 2.42988 10.7747 0.110191 6.60227 1.43149C4.59068 2.06885 2.94752 3.45368 1.97772 5.29588H4.23494V6.35461H0V2.11967H1.05874V4.7718C2.15982 2.69986 4.01367 1.14034 6.28359 0.42146Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.81201 4.23703L7.47372 9.53071H10.5261L11.1878 4.23703H6.81201ZM9.59119 8.47198H8.40858L8.01156 5.29577H9.98822L9.59119 8.47198Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5879 13.7657H7.41168V10.5894H10.5879V13.7657ZM8.47041 12.7069H9.52915V11.6482H8.47041V12.7069Z"
    />
  </SVGIcon>
)

export { IconVersioningLine }
