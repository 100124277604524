import { SVGIcon } from '@instructure/ui-svg-images'
import type { SVGIconProps } from '@instructure/ui-svg-images'

function IconMasteryLogo(props: SVGIconProps) {
  return (
    <SVGIcon name={'IconMasteryLogo'} viewBox="0 0 32 21" {...props}>
      <path d="M 23.3726,9.80861 V 4.12359 C 23.3763,3.43085 23.189,2.75049 22.8312,2.15725 22.4735,1.56401 21.9592,1.08085 21.3448,0.760839 20.7304,0.440828 20.0397,0.296352 19.3485,0.343282 c -0.6912,0.04693 -1.356,0.283451 -1.9215,0.683578 -0.5655,0.40013 -1.0098,0.94838 -1.2841,1.58453 -0.2742,0.63615 -0.3678,1.33559 -0.2705,2.02147 0.0973,0.68588 0.3818,1.33167 0.8222,1.86642 0.4404,0.53476 1.0196,0.93779 1.6741,1.16481 z m 4.783,10.99199 c 0.902,0.0246 1.7825,-0.2776 2.4793,-0.8508 0.6969,-0.5732 1.1632,-1.3789 1.3131,-2.2687 0.1499,-0.8898 -0.0267,-1.8037 -0.4973,-2.5736 -0.4706,-0.7699 -1.2035,-1.3438 -2.0638,-1.6162 l -5.0001,-2.1445 v 5.685 c 10e-4,0.9993 0.3984,1.9573 1.105,2.6639 0.7065,0.7066 1.6646,1.1039 2.6638,1.1049 z M 11.1792,9.80862 V 4.12361 C 11.1831,3.43064 10.9959,2.75001 10.6382,2.15652 10.2804,1.56303 9.76598,1.07966 9.15137,0.759551 8.53677,0.439441 7.8458,0.29498 7.15443,0.342049 6.46306,0.389118 5.79805,0.625894 5.2325,1.02635 4.66695,1.42681 4.22276,1.97544 3.94875,2.61194 3.67474,3.24844 3.58153,3.94816 3.67936,4.63419 3.77719,5.32023 4.06227,5.96601 4.50327,6.50055 4.94426,7.03509 5.5241,7.43769 6.17904,7.66411 Z m 4.783,10.99198 c 0.9024,0.0256 1.7835,-0.2759 2.4811,-0.8488 0.6976,-0.5729 1.1647,-1.3786 1.3151,-2.2687 0.1504,-0.8901 -0.026,-1.8046 -0.4966,-2.5749 -0.4707,-0.7703 -1.2039,-1.3445 -2.0645,-1.6169 l -5.0002,-2.1445 v 5.685 c 10e-4,0.9986 0.3979,1.9561 1.1037,2.6626 0.7057,0.7064 1.6628,1.1043 2.6614,1.1062 z M 3.77255,13.263 C 3.02699,13.2623 2.29796,13.4827 1.67769,13.8964 1.05742,14.31 0.573786,14.8984 0.28796,15.587 0.00213431,16.2756 -0.0730347,17.0335 0.0719624,17.7648 0.21696,18.4961 0.575608,19.168 1.10253,19.6954 1.62946,20.2229 2.30099,20.5822 3.03217,20.7279 3.76335,20.8737 4.52132,20.7992 5.2102,20.5141 5.89907,20.229 6.4879,19.7459 6.90219,19.1261 7.31647,18.5062 7.5376,17.7774 7.5376,17.0318 7.53662,16.0332 7.13974,15.0758 6.43398,14.3693 5.72821,13.6628 4.77114,13.265 3.77255,13.263 Z" />
    </SVGIcon>
  )
}

export {IconMasteryLogo}
