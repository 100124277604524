import { SVGIcon } from '@instructure/ui-svg-images'

function IconPowerStandard(props) {
  return (
    <SVGIcon
      width="16"
      height="16"
      name={'IconPowerStandard'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
        fill="#0374B5"
      />
      <path
        d="M5.81304 13.6271L5.14897 13.4492L7.01817 9.12954L4.75428 8.52293C4.43231 8.43666 4.38956 8.27423 4.62603 8.03563C4.71336 7.95119 4.73151 7.9237 4.68048 7.95317C6.18667 6.43717 8.02206 4.57803 10.1866 2.37572L10.8507 2.55366L8.98152 6.87331L11.2454 7.47992C11.5271 7.55541 11.58 7.72054 11.4038 7.97531L5.81304 13.6271Z"
        fill="white"
      />
    </SVGIcon>
  )
}

export { IconPowerStandard }
