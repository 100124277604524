import { SVGIcon } from '@instructure/ui-svg-images'
import type { SVGIconProps } from '@instructure/ui-svg-images'

export const IconPassagesLine = (props: SVGIconProps) => (
  <SVGIcon name={'IconPassagesLine'} viewBox="0 0 15 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1259 15.0588H1.8318V0.94118H9.36121V4.70589H13.1259V15.0588ZM10.3024 1.136L12.9311 3.76471H10.3024V1.136ZM13.6539 3.15671L10.9104 0.413176C10.6431 0.146824 10.2883 0 9.9118 0H0.890625V16H14.0671V4.15529C14.0671 3.77882 13.9203 3.424 13.6539 3.15671ZM0.890625 0H14.0671V16H0.890625V0ZM0.890625 16H14.0671V0H0.890625V16ZM3.71417 4.70588H7.47887V3.76471H3.71417V4.70588ZM3.71417 8.47059H9.36123V7.52941H3.71417V8.47059ZM3.71417 12.2353H7.47887V11.2941H3.71417V12.2353ZM3.71417 6.58824H11.2436V5.64706H3.71417V6.58824ZM3.71417 10.3529H10.3024V9.41177H3.71417V10.3529Z"
    />
  </SVGIcon>
)
