import { SVGIcon } from '@instructure/ui-svg-images'

function IconNoResultsPanda(props) {
  return (
    <SVGIcon width="162px" height="168px" name={"IconNoResultsPanda"} viewBox="0 0 162 168" {...props}>
      <path d="M81 166C125.183 166 161 160.627 161 154C161 150.245 149.502 146.893 131.5 144.693H30.5C12.4976 146.893 1 150.245 1 154C1 160.627 36.8172 166 81 166Z" fill="#152633" stroke="#152633" strokeWidth="2"/>
      <path d="M36.454 164.068C34.5203 161.124 32.973 157.818 31.847 154.164C24.7193 131.032 43.81 100.213 72.3792 94.9201C100.948 89.6275 126.429 111.147 130.908 135.326C131.49 138.467 131.647 141.48 131.412 144.355L131.372 144.801C130.649 152.291 127.247 158.832 121.745 164.239C94.5 167.5 54.5 166 36.454 164.068Z" fill="white"/>
      <path d="M81.3181 166.147C95.2918 166.187 109.754 165.674 121.745 164.239C127.246 158.832 130.649 152.291 131.372 144.801L131.412 144.355C131.647 141.48 131.49 138.467 130.908 135.326C126.812 113.215 105.154 93.329 79.6326 94.1353C103.835 94.9007 123.974 114.087 127.908 135.326C128.49 138.467 128.647 141.48 128.412 144.355L128.372 144.801C127.649 152.291 124.246 158.832 118.745 164.239C107.61 165.572 94.3447 166.109 81.3181 166.147Z" fill="#EDEDED"/>
      <path d="M36.454 164.068C34.5203 161.124 32.973 157.818 31.847 154.164C24.7193 131.032 43.81 100.213 72.3792 94.9201C100.948 89.6275 126.429 111.147 130.908 135.326C131.49 138.467 131.647 141.48 131.412 144.355L131.372 144.801C130.649 152.291 127.247 158.832 121.745 164.239C94.5 167.5 54.5 166 36.454 164.068Z" fill="white" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M87.8576 157.447C87.4722 155.367 90.6207 153.04 94.8893 152.249C99.1584 151.458 102.931 152.503 103.317 154.583C103.702 156.662 100.554 158.989 96.285 159.78C92.0159 160.571 88.2429 159.526 87.8576 157.447Z" fill="#152633"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M67.9467 165.5C76.0076 161.267 82.0067 150.051 80.0667 139.579C77.524 125.852 66.8842 113.519 58.1075 115.145C53.7384 115.954 49.1274 120.218 45.8679 126.59C42.5702 133.037 41.2754 140.518 42.3943 146.558C43.9551 154.983 48.4989 161.765 54 165.061C59 165.5 63.5 166 67.9467 165.5Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M71.2855 138.448C73.6107 138.689 75.1881 141.847 74.8089 145.501C74.4299 149.155 72.2371 151.921 69.912 151.68C67.5867 151.438 66.0092 148.281 66.3883 144.627C66.7678 140.973 68.9604 138.207 71.2855 138.448Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M104.876 145.607C108.559 149.409 112.281 151.009 115.34 150.526C115.395 150.518 115.446 150.509 115.496 150.5C117.681 150.095 120.265 148.456 121.743 146.366C125.347 141.271 126.368 136.225 124.955 128.596C122.726 116.568 108.412 103.202 99.7787 104.801C97.9071 105.148 96.5015 106.066 95.5454 107.555C92.6991 111.987 93.4108 120.975 96.881 132.088C98.3712 136.862 101.252 141.864 104.876 145.607Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M111.313 135.572C112.676 138.257 112.396 141.136 110.688 142.003C108.979 142.871 106.49 141.397 105.127 138.713C103.764 136.029 104.044 133.15 105.752 132.282C107.46 131.415 109.95 132.888 111.313 135.572Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.1099 125.296C32.5578 124.965 30.8538 124.392 29.0789 123.442C22.2516 119.787 21.9723 111.638 22.0017 109.583C22.0305 107.528 22.6467 97.5736 30.9858 100.539C36.3713 102.453 42.2862 106.32 45.8029 108.819C40.8992 113.582 36.9316 119.217 34.1099 125.296Z" fill="#2D3B45"/>
      <path d="M27.0789 119.442C28.8538 120.392 30.5578 120.965 32.1099 121.296C34.6254 115.876 38.0516 110.81 42.2398 106.4C43.5797 107.267 44.7907 108.1 45.8029 108.819C40.8992 113.582 36.9316 119.217 34.1099 125.296C32.5578 124.965 30.8538 124.392 29.0789 123.442C25.6265 121.593 23.8485 118.596 22.9381 115.819C23.9413 117.209 25.2837 118.481 27.0789 119.442Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M34.1099 125.296C32.5578 124.965 30.8538 124.392 29.0789 123.442C22.2516 119.787 21.9723 111.638 22.0017 109.583C22.0305 107.528 22.6467 97.5736 30.9858 100.539C36.3713 102.453 42.2862 106.32 45.8029 108.819C40.8992 113.582 36.9316 119.217 34.1099 125.296Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M114.803 103.513C114.1 104.326 113.374 105.015 112.651 105.598C107.988 101.816 102.652 98.7785 96.8657 96.7789C98.9397 93.668 102.471 88.8025 106.177 85.6296C111.986 80.6574 115.563 88.4958 116.223 90.1444C116.883 91.7933 119.179 98.4521 114.803 103.513Z" fill="#2D3B45"/>
      <path d="M111.651 101.598C112.374 101.015 113.1 100.326 113.803 99.5133C116.355 96.5619 116.638 93.0672 116.299 90.3395C117.038 92.2659 119.023 98.6325 114.803 103.513C114.1 104.326 113.374 105.015 112.651 105.598C107.988 101.816 102.652 98.7785 96.8657 96.7789C97.4299 95.9327 98.1018 94.9567 98.8557 93.9203C103.496 95.8624 107.802 98.4765 111.651 101.598Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M114.803 103.513C114.1 104.326 113.374 105.015 112.651 105.598C107.988 101.816 102.652 98.7785 96.8657 96.7789C98.9397 93.668 102.471 88.8025 106.177 85.6296C111.986 80.6574 115.563 88.4958 116.223 90.1444C116.883 91.7933 119.179 98.4521 114.803 103.513Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <ellipse cx="50" cy="23.5" rx="40" ry="22.5" fill="white" stroke="#152633" strokeWidth="2"/>
      <path d="M41.3201 11.92C45.4374 11.4934 49.2774 11.28 52.8401 11.28C54.9307 11.28 56.4667 11.7707 57.4481 12.752C58.4294 13.7334 58.9201 15.2694 58.9201 17.36V18.8C58.9201 20.4 58.6001 21.616 57.9601 22.448C57.3201 23.3014 56.2001 24.0054 54.6001 24.56L51.4001 25.68V27.28H45.6401V22.8L53.1601 20.08V17.36C53.1601 16.8694 53.0107 16.4854 52.7121 16.208C52.4347 15.9094 51.9441 15.76 51.2401 15.76C49.9601 15.76 48.2961 15.824 46.2481 15.952C44.2214 16.08 42.5787 16.176 41.3201 16.24V11.92ZM45.6401 29.2H51.4001V34H45.6401V29.2Z" fill="#152633"/>
      <ellipse cx="27" cy="58" rx="7" ry="4" fill="white" stroke="#152633" strokeWidth="2"/>
      <ellipse cx="34" cy="73" rx="5" ry="3" fill="white" stroke="#152633" strokeWidth="2"/>
      <ellipse cx="46" cy="82.5" rx="4" ry="2.5" fill="white" stroke="#152633" strokeWidth="2"/>
    </SVGIcon>
  )
}

export { IconNoResultsPanda }