import { SVGIcon } from '@instructure/ui-svg-images'
import type { SVGIconProps } from '@instructure/ui-svg-images'

const IconGradecamLine = (props: SVGIconProps) => (
  <SVGIcon
    name={'IconGradecamLine'}
    viewBox="0 0 26 16"
    color="primary-inverse"
    {...props}
  >
    {/* fill color should be white (opposite of primary) even through stroke color is secondary */}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.49999 1H17.9639C20.8536 1 23.3344 2.75106 24.403 5.25H21.1187C20.3574 4.35085 19.2204 3.78003 17.95 3.78003C15.658 3.78003 13.8 5.63805 13.8 7.93003C13.8 10.222 15.658 12.08 17.95 12.08C19.644 12.08 21.101 11.065 21.7459 9.60999H17.8V7.25L24.9241 7.24998C24.9504 7.49639 24.9639 7.74662 24.9639 8C24.9639 11.866 21.8298 15 17.9639 15C15.6249 15 13.5538 13.8528 12.2826 12.0904H11.2506C9.76475 12.0904 8.56024 10.8859 8.56024 9.40002H10.6L8.11003 9.39998C6.50288 9.39998 5.20002 8.09713 5.20002 6.48997H10.6V6.48193H3.73194C2.22313 6.48193 1 5.2588 1 3.74999H11.8V3.74096H5.24096C3.72716 3.74096 2.49999 2.51379 2.49999 1Z"
      stroke="#6B7780"
      strokeLinejoin="round"
    />
  </SVGIcon>
)

export { IconGradecamLine }
