import { SVGIcon } from '@instructure/ui-svg-images'

function IconEnhancedItem(props) {
  return (
      <SVGIcon name={"IconEnhancedItem"} viewBox="0 0 1920 1260" {...props}>
        <path d="M528.6 385.72V578.2H771.48V677.56H528.6V873.88H836.76V977.08H399V283H836.76V385.72H528.6Z"/>
        <path d="M1317.91 715L1233.43 484.12C1229.27 473.88 1224.95 461.72 1220.47 447.64C1215.99 433.56 1211.51 418.36 1207.03 402.04C1202.87 418.36 1198.55 433.72 1194.07 448.12C1189.59 462.2 1185.27 474.52 1181.11 485.08L1097.11 715H1317.91ZM1545.91 977.08H1446.07C1434.87 977.08 1425.75 974.36 1418.71 968.92C1411.67 963.16 1406.39 956.12 1402.87 947.8L1351.03 806.2H1063.51L1011.67 947.8C1009.11 955.16 1004.15 961.88 996.787 967.96C989.427 974.04 980.307 977.08 969.427 977.08H868.627L1141.75 283H1273.27L1545.91 977.08Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M1920 1260H0V0H1920V1260ZM1807 113H113V1147H1807V113Z"/>
      </SVGIcon>
  )
}

export {IconEnhancedItem}