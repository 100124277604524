import { SVGIcon } from '@instructure/ui-svg-images'

function IconClassicItem(props) {
  return (
    <SVGIcon name={'IconClassicItem'} viewBox="0 0 1400 1260" {...props}>
      <path d="M911.96 813.08C919 813.08 925.24 815.8 930.68 821.24L981.56 876.44C953.4 911.32 918.68 938.04 877.4 956.6C836.44 975.16 787.16 984.44 729.56 984.44C678.04 984.44 631.64 975.64 590.36 958.04C549.4 940.44 514.36 915.96 485.24 884.6C456.12 853.24 433.72 815.8 418.04 772.28C402.68 728.76 395 681.24 395 629.72C395 577.56 403.32 529.88 419.96 486.68C436.6 443.16 459.96 405.72 490.04 374.36C520.44 343 556.6 318.68 598.52 301.4C640.44 283.8 686.84 275 737.72 275C788.28 275 833.08 283.32 872.12 299.96C911.48 316.6 944.92 338.36 972.44 365.24L929.24 425.24C926.68 429.08 923.32 432.44 919.16 435.32C915.32 438.2 909.88 439.64 902.84 439.64C898.04 439.64 893.08 438.36 887.96 435.8C882.84 432.92 877.24 429.56 871.16 425.72C865.08 421.56 858.04 417.08 850.04 412.28C842.04 407.48 832.76 403.16 822.2 399.32C811.64 395.16 799.32 391.8 785.24 389.24C771.48 386.36 755.48 384.92 737.24 384.92C706.2 384.92 677.72 390.52 651.8 401.72C626.2 412.6 604.12 428.6 585.56 449.72C567 470.52 552.6 496.12 542.36 526.52C532.12 556.6 527 591 527 629.72C527 668.76 532.44 703.48 543.32 733.88C554.52 764.28 569.56 789.88 588.44 810.68C607.32 831.48 629.56 847.48 655.16 858.68C680.76 869.56 708.28 875 737.72 875C755.32 875 771.16 874.04 785.24 872.12C799.64 870.2 812.76 867.16 824.6 863C836.76 858.84 848.12 853.56 858.68 847.16C869.56 840.44 880.28 832.28 890.84 822.68C894.04 819.8 897.4 817.56 900.92 815.96C904.44 814.04 908.12 813.08 911.96 813.08Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1400 1260H0V0H1400V1260ZM1287 113H113V1147H1287V113Z"
      />
    </SVGIcon>
  )
}

export { IconClassicItem }
