import type { SVGIconProps } from '@instructure/ui-svg-images'
import { SVGIcon } from '@instructure/ui-svg-images'

export const IconListView = (props: SVGIconProps) => {
  return (
    <SVGIcon viewBox="0 0 18 18" {...props}>
      <g clipPath="url(#clip0_59_9402)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.2 1.2V6.8H16.8V1.2H1.2ZM1.2 11.2V16.8H16.8V11.2H1.2ZM1 0C0.447715 0 0 0.447715 0 1V7C0 7.55228 0.447716 8 1 8H17C17.5523 8 18 7.55228 18 7V1C18 0.447715 17.5523 0 17 0H1ZM1 10C0.447715 10 0 10.4477 0 11V17C0 17.5523 0.447716 18 1 18H17C17.5523 18 18 17.5523 18 17V11C18 10.4477 17.5523 10 17 10H1Z"
        />
      </g>
    </SVGIcon>
  )
}
