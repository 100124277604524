import { InlineSVG } from '@instructure/ui-svg-images'

function IconInstructureLogo(props) {
  return (
    <InlineSVG title={"IconInstructureLogo"} viewBox="0 0 2000 282" {...props}>
      <path d="M-.7,209.1l130.5,73.5,130.5-73.5L129.8,135.6ZM200.1,107.3l60.2-33.8L200,39.5,139.8,73.4Zm-10-73.4L129.8,0,69.6,33.9l60.2,33.9ZM119.8,73.5l-60.2-34L-.7,73.4l60.3,33.9ZM383.1,245.2V35h34.8V245.2Zm96.1,0V35H514l44.1,130V35h31.8V245.2h-33L511,108.6V245.2ZM715.4,96V80.7c0-13.3-7.5-18-17.7-18S680,67.5,680,80.7V95.1c0,10.8,3.6,15.6,13.5,21.3l24.6,13.8c20.1,11.4,32.1,20.7,32.1,43.2v30.4c0,32.4-20.1,43.8-51.9,43.8h-.6c-31.8,0-51.9-11.1-51.9-43.8V181.3H680V199c0,12.9,7.5,18.6,18,18.6s18-5.7,18-18.6V182.5c0-10.8-3.3-16.2-13.8-22l-24.3-13.8c-20.4-11.7-32.1-21.3-32.1-43.2V75.9c0-31.2,22.8-43.2,51.6-43.2h.6c28.8,0,51.7,12,51.7,43.2V96ZM897.3,65.6H862.2V245.2H827.4V65.6H792.3V35H897.4V65.6Zm127.1,179.6-26.8-83.8H980.3v83.8H945.4V35h54.3c31.9,0,52.9,12.6,52.9,44.4V117c0,20.1-8.4,32.7-22.8,39l30,89.2ZM980.2,131.4h18.3c11.4,0,19.2-4.2,19.2-19.2V84.9c0-15-7.8-19.2-19.2-19.2H980.2ZM1215,203.1c0,32.5-20.4,44.5-52.3,44.5h-1.2c-31.8,0-52.2-11.7-52.2-44.5V35h34.8V197.7c0,15.1,6.6,19.3,18,19.3s18-4.2,18-19.3V35H1215V203.1Zm164.5,0c0,32.5-21,44.5-52.8,44.5h-.6c-31.8,0-52.8-11.7-52.8-44.5V77c0-31.2,21-44.4,52.8-44.4h.6c31.8,0,52.8,13.2,52.8,44.4v24.4h-34.8v-19c0-14.7-7.2-19.2-18.3-19.2s-18.3,4.5-18.3,19.2V197.7c0,14.8,7.2,19.3,18.3,19.3s18.3-4.5,18.3-19.3v-24h34.8ZM1529,65.6h-35.1V245.2h-34.8V65.6H1424V35h105V65.6Zm153.1,137.5c0,32.5-20.4,44.5-52.2,44.5h-1.2c-31.8,0-52.3-11.7-52.3-44.5V35h34.9V197.7c0,15.1,6.6,19.3,18,19.3s18-4.2,18-19.3V35h34.8V203.1Zm138.5,42.1-26.8-83.8h-17.4v83.8h-34.8V35H1796c31.8,0,52.8,12.6,52.8,44.4V117c0,20.1-8.4,32.7-22.8,39l30,89.2Zm-44.2-113.8h18.3c11.4,0,19.2-4.2,19.2-19.2V84.9c0-15-7.8-19.2-19.2-19.2h-18.3ZM1907,245.1V35h92.1V65.6h-57.3v57.6h48v30.6h-48v60.6H2000v30.7Z" />
    </InlineSVG>
  )
}

export {IconInstructureLogo}