function IconCoursesPanda() {
  return (
    <svg
      name={'IconCoursesPanda'}
      width="100"
      height="133"
      fill="none"
      viewBox="0 0 100 133"
    >
      <path d="M50 132.154C77.6142 132.154 100 127.565 100 121.904C100 116.243 77.6142 111.654 50 111.654C22.3858 111.654 0 116.243 0 121.904C0 127.565 22.3858 132.154 50 132.154Z" fill="black" fillOpacity="0.05"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M69.9319 74.6541C63.6098 76.7594 56.3946 77.9037 48.7321 77.9037C41.9021 77.9037 35.5841 77.0979 30.0066 75.5181C27.7669 80.5331 25.23 86.9591 24.6744 91.4037C23.0402 102.841 32.9821 112.865 50.636 112.865C68.2898 112.865 77.5124 102.481 75.4974 91.7499C74.1086 84.3571 71.8701 78.7719 69.9319 74.6541Z" fill="white"/>
      <path d="M31.2007 107.073C35.4523 109.449 40.9992 110.865 47.636 110.865C65.2899 110.865 74.5124 100.481 72.4975 89.7499C71.3795 83.7984 69.7107 79.0184 68.0913 75.2324C68.7128 75.0485 69.3265 74.8557 69.932 74.6541C71.8701 78.7719 74.1087 84.3571 75.4975 91.7499C77.5124 102.481 68.2899 112.865 50.636 112.865C42.3046 112.865 35.6907 110.633 31.2007 107.073Z" fill="#EDEDED"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M69.932 74.6541C63.6098 76.7594 56.3946 77.9037 48.7321 77.9037C41.9021 77.9037 35.5841 77.0979 30.0066 75.5181C27.7669 80.5331 25.23 86.9591 24.6744 91.4037C23.0402 102.841 32.9821 112.865 50.636 112.865C68.2898 112.865 77.5124 102.481 75.4974 91.7499C74.1086 84.3571 71.8701 78.7719 69.932 74.6541Z" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M84.8736 117.924C82.1043 103.732 75.9865 95.2631 75.6404 92.4939C76.9897 101.923 69.5604 111.075 55.5 112.784L55.7967 114.117C56.8351 119.655 58.912 121.732 62.3736 122.77C69.989 125.54 86.6043 124.501 84.8736 117.924Z" fill="#2D3B45"/>
      <path d="M75.5755 97.2561C77.6392 101.63 80.298 107.851 81.8733 115.924C83.6041 122.501 66.9887 123.54 59.3733 120.77C59.0553 120.675 58.749 120.571 58.4541 120.456C59.492 121.601 60.7873 122.295 62.3733 122.77C69.9887 125.54 86.6041 124.501 84.8733 117.924C83.0178 108.415 79.6589 101.475 77.5402 97.0974C76.5246 94.9991 75.794 93.4896 75.6505 92.5681C75.8693 94.1504 75.8409 95.7244 75.5755 97.2561Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M84.8736 117.924C82.1043 103.732 75.9865 95.2631 75.6404 92.4939C76.9897 101.923 69.5604 111.075 55.5 112.784L55.7967 114.117C56.8351 119.655 58.912 121.732 62.3736 122.77C69.989 125.54 86.6043 124.501 84.8736 117.924Z" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.5225 92.6541C24.1255 95.424 17.8947 103.732 15.1255 117.924C13.3947 124.501 30.0101 125.539 37.6255 122.77C41.087 121.732 43.1639 119.655 44.2024 114.116L44.4845 112.963L44.4991 112.644C31.1746 110.682 23.7302 102.271 24.5225 92.6541Z" fill="#2D3B45"/>
      <path d="M15.2769 120.121C17.3822 124.7 30.9556 125.196 37.6253 122.77C41.0868 121.732 43.1638 119.655 44.2022 114.116L44.4843 112.963L44.4989 112.644C43.7054 112.528 42.9327 112.388 42.1814 112.226C41.1361 117.682 39.0638 119.739 35.6253 120.77C30.0984 122.78 19.8312 122.784 15.2769 120.121Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.5225 92.6541C24.1255 95.424 17.8947 103.732 15.1255 117.924C13.3947 124.501 30.0101 125.539 37.6255 122.77C41.087 121.732 43.1639 119.655 44.2024 114.116L44.4845 112.963L44.4991 112.644C31.1746 110.682 23.7302 102.271 24.5225 92.6541Z" stroke="#152633" strokeWidth="2"/>
      <path d="M21.7451 88.8892C22.4297 92.4002 24.4605 95.2255 27.744 96.5776C33.1669 98.8105 38.023 99.1411 42.1706 98.0483C43.7177 97.6407 45.0826 97.0497 46.1919 96.3633C47.004 95.8607 47.6072 95.3386 47.7504 95.1095C48.8099 93.4143 49.7383 88.783 49.2867 85.6829C49.1203 84.5408 48.8041 83.605 48.3151 82.8943C48.6965 85.9898 47.7861 90.4524 46.7504 92.1095C46.6072 92.3386 46.004 92.8607 45.1919 93.3633C44.0826 94.0497 42.7177 94.6407 41.1706 95.0483C37.023 96.1411 32.1669 95.8105 26.744 93.5776C24.4624 92.6381 22.7856 90.9872 21.7451 88.8892Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.52246 46.9879C6.52246 67.0648 24.6703 77.7956 49.6186 77.7956C74.567 77.7956 94.7917 65.6802 94.7917 47.6802C94.7917 25.3127 74.567 3.71863 49.6186 3.71863C24.6703 3.71863 6.52246 25.8725 6.52246 46.9879Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.52246 46.9879C6.52246 67.0648 24.6703 77.7956 49.6186 77.7956C74.567 77.7956 94.7917 65.6802 94.7917 47.6802C94.7917 25.3127 74.567 3.71863 49.6186 3.71863C24.6703 3.71863 6.52246 25.8725 6.52246 46.9879Z" fill="white"/>
      <path d="M17.4795 69.0406C24.9872 73.4982 35.0748 75.7955 46.6185 75.7955C71.5668 75.7955 91.7915 63.6801 91.7915 45.6801C91.7915 32.0354 84.2653 18.6784 72.7316 10.3358C85.9429 18.4286 94.7915 32.8852 94.7915 47.6801C94.7915 65.6801 74.5668 77.7955 49.6185 77.7955C36.4578 77.7955 25.1896 74.8095 17.4795 69.0406Z" fill="#EDEDED"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.52246 46.9879C6.52246 67.0648 24.6703 77.7956 49.6186 77.7956C74.567 77.7956 94.7917 65.6802 94.7917 47.6802C94.7917 25.3127 74.567 3.71863 49.6186 3.71863C24.6703 3.71863 6.52246 25.8725 6.52246 46.9879Z" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M57.4072 58.9301C57.4072 60.7463 54.3853 62.2185 50.6572 62.2185C46.9291 62.2185 43.9072 60.7463 43.9072 58.9301C43.9072 57.1138 46.9291 55.6416 50.6572 55.6416C54.3853 55.6416 57.4072 57.1138 57.4072 58.9301Z" fill="#152633"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M71.9456 18.5646C64.3794 18.5646 57.3687 30.5682 57.3687 42.4877C57.3687 53.1345 66.6832 62.9492 75.4071 62.9492C83.3622 62.9492 89.984 53.8258 89.984 42.4877C89.984 37.246 87.7316 31.1621 83.9574 26.2586C80.2334 21.4203 75.7103 18.5646 71.9456 18.5646Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M70.133 48.4038C70.133 51.0269 68.76 53.1535 67.0665 53.1535C65.373 53.1535 64 51.0269 64 48.4038C64 45.7807 65.373 43.6541 67.0665 43.6541C68.76 43.6541 70.133 45.7807 70.133 48.4038Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M25.5608 62.9493C34.2847 62.9493 43.5993 53.1347 43.5993 42.4878C43.5993 30.5681 36.5886 18.5647 29.0223 18.5647C25.2574 18.5647 20.7343 21.4203 17.0105 26.2588C13.2361 31.162 10.9839 37.2456 10.9839 42.4878C10.9839 53.8259 17.6057 62.9493 25.5608 62.9493Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M37.133 48.4038C37.133 51.0269 35.76 53.1535 34.0665 53.1535C32.373 53.1535 31 51.0269 31 48.4038C31 45.7807 32.373 43.6541 34.0665 43.6541C35.76 43.6541 37.133 45.7807 37.133 48.4038Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M86.6799 23.3787C87.9388 22.8564 89.2875 22.1062 90.6378 21.0262C95.8301 16.8724 94.7916 9.94932 94.4455 8.21855C94.0993 6.48778 92.0224 -1.81991 85.4455 1.98778C81.1978 4.44686 76.8086 8.63601 74.2305 11.2969C79.1157 14.5507 83.3468 18.6873 86.6799 23.3787Z" fill="#2D3B45"/>
      <path d="M89.6378 18.0262C88.2875 19.1062 87.9388 19.4776 86.6799 19.9999C83.8889 16.0715 79.4682 12.9109 75.5611 9.95308C75.0785 10.4299 74.6325 10.882 74.2305 11.2968C79.1157 14.5507 83.3468 18.6872 86.6799 23.3786C87.9388 22.8563 89.2875 22.1062 90.6378 21.0262C95.8301 16.8723 94.7916 9.94926 94.4455 8.21849C94.3273 7.62742 94.0072 6.26933 93.375 4.88867C93.4037 5.0154 93.427 5.12628 93.4455 5.21849C93.7916 6.94926 94.8301 13.8723 89.6378 18.0262Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M86.6799 23.3787C87.9388 22.8564 89.2875 22.1062 90.6378 21.0262C95.8301 16.8724 94.7916 9.94932 94.4455 8.21855C94.0993 6.48778 92.0224 -1.81991 85.4455 1.98778C81.1978 4.44686 76.8086 8.63601 74.2305 11.2969C79.1157 14.5507 83.3468 18.6873 86.6799 23.3787Z" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4325 23.3787C12.1739 22.8564 10.8249 22.1062 9.47458 21.0262C4.28227 16.8724 5.32074 9.94932 5.66689 8.21855C6.01305 6.48778 8.08997 -1.81991 14.6669 1.98778C18.9146 4.44686 23.3038 8.63601 25.8819 11.2969C20.997 14.5507 16.766 18.6873 13.4325 23.3787Z" fill="#2D3B45"/>
      <path d="M8.47428 17.0262C9.82463 18.1062 11.1736 18.8564 12.4322 19.3787C15.3521 15.2694 18.9606 11.5858 23.094 8.54822C24.1532 9.54431 25.1005 10.4907 25.8816 11.2969C20.9967 14.5507 16.7657 18.6873 13.4322 23.3787C12.1736 22.8564 10.8246 22.1062 9.47428 21.0262C6.63615 18.7557 5.65961 15.6578 5.41406 13.0328C6.04259 14.4651 7.01101 15.8556 8.47428 17.0262Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.4325 23.3787C12.1739 22.8564 10.8249 22.1062 9.47458 21.0262C4.28227 16.8724 5.32074 9.94932 5.66689 8.21855C6.01305 6.48778 8.08997 -1.81991 14.6669 1.98778C18.9146 4.44686 23.3038 8.63601 25.8819 11.2969C20.997 14.5507 16.766 18.6873 13.4325 23.3787Z" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M64.8 104.654C65.9201 104.654 66.4802 104.654 66.908 104.436C67.2843 104.244 67.5903 103.938 67.782 103.562C68 103.134 68 102.574 68 101.454V71.5H33V101.454C33 102.574 33 103.134 33.218 103.562C33.4097 103.938 33.7157 104.244 34.092 104.436C34.5198 104.654 35.0799 104.654 36.2 104.654H64.8Z" fill="#5DB9F2" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M68 73.6541V67.6541H36C34.3431 67.6541 33 68.9972 33 70.6541C33 72.3109 34.3431 73.6541 36 73.6541H68Z" fill="white" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27.1181 73.7924C25.0246 75.5001 23.3553 78.3177 22.5205 81.7604C20.9415 88.2717 22.9833 94.2464 28.2585 96.4185C33.6814 98.6515 38.5375 98.9821 42.6851 97.8893C44.2322 97.4816 45.5971 96.8907 46.7063 96.2042C47.5185 95.7017 48.1217 95.1796 48.2649 94.9505C49.3244 93.2553 50.2528 88.624 49.8012 85.5239C49.221 81.5416 46.8195 80.0681 41.6209 81.8946C36.656 83.6391 33.5132 83.6697 31.854 81.9811C30.4994 80.6023 30.403 78.5328 31.0476 75.4853L27.1181 73.7924Z" fill="#2D3B45"/>
      <path d="M24.1914 93.2607C25.2055 94.6329 26.5653 95.7215 28.2581 96.4186C33.6809 98.6515 38.5371 98.9821 42.6847 97.8893C44.2317 97.4816 45.5966 96.8907 46.7059 96.2042C47.5181 95.7017 48.1212 95.1796 48.2644 94.9505C49.3239 93.2553 50.2524 88.624 49.8007 85.5239C49.4949 83.4249 48.6832 82.0229 47.2225 81.4337C47.4941 82.0249 47.6842 82.7237 47.8007 83.5239C48.2524 86.624 47.3239 91.2553 46.2644 92.9505C46.1212 93.1796 45.5181 93.7017 44.7059 94.2042C43.5966 94.8907 42.2317 95.4816 40.6847 95.8893C36.5371 96.9821 31.6809 96.6515 26.2581 94.4186C25.5025 94.1074 24.8132 93.7183 24.1914 93.2607Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.5443 74C24.4785 75.7124 22.8328 78.5092 22.0059 81.9193C20.4269 88.4307 22.4687 94.4054 27.7438 96.5775C33.1667 98.8105 38.0228 99.1411 42.1704 98.0482C43.7175 97.6406 45.0824 97.0496 46.1917 96.3632C47.0039 95.8607 47.607 95.3385 47.7502 95.1095C48.8097 93.4143 49.7382 88.7829 49.2865 85.6828C48.7064 81.7006 46.3049 80.227 41.1062 82.0536C36.1414 83.798 32.9985 83.8287 31.3394 82.1401C29.9847 80.7613 29.8553 78.5475 30.4999 75.5L26.5443 74Z" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.7999 73.7924C75.8934 75.5001 77.5626 78.3177 78.3975 81.7604C79.9764 88.2717 77.9346 94.2464 72.6595 96.4185C67.2366 98.6515 62.3805 98.9821 58.2329 97.8893C56.6858 97.4816 55.3209 96.8907 54.2116 96.2042C53.3995 95.7017 52.7963 95.1796 52.6531 94.9505C51.5936 93.2553 50.6652 88.624 51.1168 85.5239C51.697 81.5416 54.0984 80.0681 59.2971 81.8946C64.2619 83.6391 67.4048 83.6697 69.0639 81.9811C70.4186 80.6023 70.5149 78.5328 69.8704 75.4853L73.7999 73.7924Z" fill="#2D3B45"/>
      <path d="M73.7187 73.8274C74.9041 75.4271 75.8383 77.4534 76.3977 79.7604C77.9767 86.2717 75.9349 92.2464 70.6597 94.4185C65.2368 96.6515 60.3807 96.9821 56.2331 95.8893C54.7242 95.4917 53.3886 94.9197 52.2944 94.2549C52.4111 94.5211 52.5313 94.7553 52.6533 94.9505C52.7965 95.1796 53.3997 95.7017 54.2119 96.2042C55.3211 96.8907 56.686 97.4816 58.2331 97.8893C62.3807 98.9821 67.2368 98.6515 72.6597 96.4185C77.9349 94.2464 79.9767 88.2717 78.3977 81.7604C77.5628 78.3177 75.8936 75.5001 73.8001 73.7924L73.7187 73.8274Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M73.9999 73.5C76.0657 75.2124 78.0851 78.5092 78.9121 81.9193C80.4911 88.4307 78.4493 94.4054 73.1741 96.5775C67.7513 98.8105 62.8951 99.1411 58.7475 98.0482C57.2004 97.6406 55.8356 97.0496 54.7263 96.3632C53.9141 95.8607 53.3109 95.3385 53.1678 95.1095C52.1082 93.4143 51.1798 88.7829 51.6315 85.6828C52.2116 81.7006 54.6131 80.227 59.8117 82.0536C64.7766 83.798 67.9195 83.8287 69.5786 82.1401C70.9332 80.7613 70.9999 78 69.9999 75L73.9999 73.5Z" stroke="#152633" strokeWidth="2"/>
    </svg>
  )
}

export { IconCoursesPanda }
