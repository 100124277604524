import { SVGIcon } from '@instructure/ui-svg-images'

function IconSleepingPanda(props) {
  return (
    <SVGIcon width="168" height="191" name={"IconSleepingPanda"} viewBox="0 0 168 191"  fill="none" {...props}>
      <path d="M84 1.90.773C130.392 190.773 168 183.834 168 175.273C168 166.713 130.392 159.773 84 159.773C37.6081 159.773 0 166.713 0 175.273C0 183.834 37.6081 190.773 84 190.773Z" fill="black" fillOpacity="0.05"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M128.082 119.518C128.568 118.208 129.83 117.273 131.31 117.273C132.79 117.273 134.051 118.208 134.537 119.518C135.856 119.084 137.363 119.479 138.289 120.622C139.226 121.779 139.289 123.36 138.562 124.565C139.735 125.332 140.364 126.775 140.031 128.22C139.698 129.659 138.509 130.677 137.126 130.858C137.256 132.244 136.532 133.628 135.207 134.274C133.872 134.925 132.321 134.634 131.31 133.659C130.299 134.634 128.748 134.925 127.412 134.274C126.088 133.628 125.364 132.244 125.494 130.858C124.111 130.677 122.921 129.659 122.589 128.22C122.255 126.775 122.885 125.332 124.058 124.565C123.331 123.36 123.394 121.779 124.331 120.622C125.256 119.479 126.763 119.084 128.082 119.518Z" fill="#28343D" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M70.0018 138.869L69.9332 139.037L69.8102 139.17C63.2873 146.22 66.1298 153.659 78.9129 161.967C88.3535 168.103 105.114 171.944 120.107 171.627C135.91 171.293 146 166.535 146 158.505C146 148.183 143.177 137.828 137.707 130.008C131.229 120.746 121.43 115.384 108.326 115.01C89.4477 114.47 76.7395 122.39 70.0018 138.869Z" fill="white" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M144.685 133.353C138.264 124.32 130.859 122.655 124.522 127.21C118.692 131.4 115.204 140.031 116.486 147.607C116.898 150.036 117.271 152.146 117.627 154C119.378 163.125 122.013 172.765 132.642 174.283C138.214 175.08 144.785 169.991 148.207 162.064C152.103 153.043 151.217 142.542 144.685 133.353Z" fill="#2D3B45"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M120.732 154.506C118.304 153.586 115.56 153.508 112.5 154.273C102.5 156.773 100.5 177.773 114 177.773C127.5 177.773 145 178.273 150 156.273" fill="#2D3B45"/>
      <path d="M141.685 130.353C141.127 129.568 140.561 128.839 139.99 128.164C141.592 129.491 143.166 131.216 144.685 133.353C151.217 142.542 152.102 153.043 148.207 162.065C148.2 162.08 148.194 162.094 148.187 162.109C141.769 177.795 126.912 177.784 114.88 177.774L114.861 177.774C114.572 177.774 114.285 177.773 114 177.773C110.141 177.773 107.549 176.058 106.048 173.55C107.383 174.324 109.025 174.773 111 174.773C111.285 174.773 111.572 174.774 111.861 174.774L111.88 174.774C123.617 174.783 138.041 174.795 144.699 160.237C144.861 159.864 145.024 159.488 145.186 159.111C145.58 158.15 145.941 157.131 146.269 156.048L146.313 156.051C148.841 147.759 147.501 138.535 141.685 130.353Z" fill="#28343D"/>
      <path d="M148.207 162.064C152.103 153.043 151.217 142.542 144.685 133.353C138.264 124.32 130.859 122.655 124.522 127.21C118.692 131.4 115.204 140.031 116.486 147.607C116.898 150.036 117.271 152.146 117.627 154" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path d="M120.732 154.506C118.304 153.586 115.56 153.508 112.5 154.273C102.5 156.773 100.5 177.773 114 177.773C127.5 177.773 145 178.273 150 156.273" fill="#2D3B45" stroke="#152633" strokeWidth="2" strokeLinecap="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.6768 145.761C21.6768 165.838 39.8246 176.569 64.7729 176.569C89.7213 176.569 109.946 164.453 109.946 146.453C109.946 124.086 89.7213 102.492 64.7729 102.492C39.8246 102.492 21.6768 124.646 21.6768 145.761Z" fill="white"/>
      <path d="M28.9707 164.479C36.6757 171.121 48.6318 174.569 62.7728 174.569C87.7212 174.569 107.946 162.454 107.946 144.454C107.946 133.935 103.474 123.588 96.1409 115.577C105.236 123.902 110.946 135.569 110.946 147.454C110.946 165.454 90.7212 177.569 65.7728 177.569C49.6378 177.569 36.3473 173.081 28.9707 164.479Z" fill="#EDEDED"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.6768 145.761C22.6768 165.838 40.8246 176.569 65.7729 176.569C90.7213 176.569 110.946 164.453 110.946 146.453C110.946 124.086 90.7213 102.492 65.7729 102.492C40.8246 102.492 22.6768 124.646 22.6768 145.761Z" fill="white" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M72.5615 157.204C72.5615 158.744 69.9873 159.992 66.8115 159.992C63.6358 159.992 61.0615 158.744 61.0615 157.204C61.0615 155.663 63.6358 154.415 66.8115 154.415C69.9873 154.415 72.5615 155.663 72.5615 157.204Z" fill="#152633"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M102.834 122.152C104.093 121.63 105.442 120.88 106.792 119.8C111.984 115.646 110.946 108.723 110.6 106.992C110.254 105.261 108.177 96.9535 101.6 100.761C97.3522 103.22 92.9629 107.409 90.3848 110.07C95.27 113.324 99.5011 117.461 102.834 122.152Z" fill="#2D3B45"/>
      <path d="M106.792 115.8C105.442 116.88 104.093 117.63 102.834 118.152C100.047 114.229 96.6322 110.694 92.7319 107.739C91.8498 108.581 91.0559 109.378 90.3848 110.07C95.27 113.324 99.5011 117.461 102.834 122.152C104.093 121.63 105.442 120.88 106.792 119.8C111.107 116.347 111.119 110.982 110.801 108.275C110.485 110.793 109.463 113.663 106.792 115.8Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M102.834 122.152C104.093 121.63 105.442 120.88 106.792 119.8C111.984 115.646 110.946 108.723 110.6 106.992C110.254 105.261 108.177 96.9535 101.6 100.761C97.3522 103.22 92.9629 107.409 90.3848 110.07C95.27 113.324 99.5011 117.461 102.834 122.152Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M29.5868 122.152C28.3282 121.63 26.9792 120.88 25.6289 119.8C20.4366 115.646 21.475 108.723 21.8212 106.992C22.1673 105.261 24.2443 96.9535 30.8212 100.761C35.0688 103.22 39.4581 107.409 42.0362 110.07C37.1513 113.324 32.9203 117.461 29.5868 122.152Z" fill="#2D3B45"/>
      <path d="M23.4404 117.411C24.5166 118.172 25.5806 118.735 26.5867 119.152C29.9127 114.471 34.1323 110.343 39.0034 107.092C40.1618 108.172 41.1953 109.203 42.0362 110.07C37.1512 113.324 32.9202 117.461 29.5867 122.152C28.3281 121.63 26.9792 120.88 25.6288 119.8C24.7214 119.074 24.0042 118.263 23.4404 117.411Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M29.5868 122.152C28.3282 121.63 26.9792 120.88 25.6289 119.8C20.4366 115.646 21.475 108.723 21.8212 106.992C22.1673 105.261 24.2443 96.9535 30.8212 100.761C35.0688 103.22 39.4581 107.409 42.0362 110.07C37.1513 113.324 32.9203 117.461 29.5868 122.152Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M88.0994 117.338C80.5332 117.338 73.5225 129.342 73.5225 141.261C73.5225 151.908 82.8371 161.723 91.5609 161.723C99.516 161.723 106.138 152.599 106.138 141.261C106.138 136.02 103.885 129.936 100.111 125.032C96.3872 120.194 91.8641 117.338 88.0994 117.338Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path d="M86.6762 148.454C86.6762 150.217 85.2124 151.646 83.4069 151.646C81.6015 151.646 80.1377 150.217 80.1377 148.454" stroke="#152633" fill="#2D3B45" strokeWidth="2" strokeLinecap="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M41.7146 161.723C50.4385 161.723 59.7531 151.908 59.7531 141.261C59.7531 129.342 52.7424 117.338 45.1762 117.338C41.4112 117.338 36.8882 120.194 33.1643 125.032C29.3899 129.935 27.1377 136.019 27.1377 141.261C27.1377 152.599 33.7596 161.723 41.7146 161.723Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path d="M52.1381 148.454C52.1381 150.217 50.6743 151.646 48.8688 151.646C47.0634 151.646 45.5996 150.217 45.5996 148.454" stroke="#152633" fill="#2D3B45" strokeWidth="2" strokeLinecap="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.6582 148.074C19.9016 150.279 17.8183 157.665 18.6805 164.715C19.8935 174.633 26.0069 181.462 37.0961 182.528C48.6724 183.641 55.8514 181.771 59.4654 177.626C62.161 174.534 62.7964 170.33 62.0121 165.259C61.3894 161.232 58.5097 157.56 54.4083 156.067C49.4119 154.248 43.0391 155.626 35.8316 160.837C31.4895 163.939 28.8932 164.124 26.2078 160.633C24.5215 158.441 23.3625 154.276 22.6582 148.074Z" fill="#2D3B45"/>
      <path d="M23.2539 175.927C26.3557 179.588 30.9656 181.939 37.0965 182.528C48.6728 183.641 55.8517 181.771 59.4658 177.626C62.1614 174.534 62.7967 170.33 62.0125 165.259C61.5701 162.399 59.9889 159.717 57.6398 157.869C58.8811 159.444 59.7111 161.31 60.0125 163.259C60.7967 168.33 60.1614 172.534 57.4658 175.626C53.8517 179.771 46.6728 181.641 35.0965 180.528C30.1685 180.054 26.2232 178.443 23.2539 175.927Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.6582 148.074C19.9016 150.279 17.8183 157.665 18.6805 164.715C19.8935 174.633 26.0069 181.462 37.0961 182.528C48.6724 183.641 55.8514 181.771 59.4654 177.626C62.161 174.534 62.7964 170.33 62.0121 165.259C61.3894 161.232 58.5097 157.56 54.4083 156.067C49.4119 154.248 43.0391 155.626 35.8316 160.837C31.4895 163.939 28.8932 164.124 26.2078 160.633C24.5215 158.441 23.3625 154.276 22.6582 148.074Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M111.092 148.27C110.2 153.535 108.95 157.229 107.292 159.383C104.339 163.223 101.791 163.804 97.9825 161.084C90.7798 155.876 84.407 154.498 79.4105 156.317C75.3091 157.81 72.4294 161.482 71.8067 165.509C71.0225 170.579 71.6578 174.784 74.3534 177.876C77.9675 182.021 85.1464 183.891 96.7227 182.778C107.812 181.712 113.925 174.883 115.138 164.964C116.008 157.856 113.882 150.407 111.092 148.27Z" fill="#2D3B45"/>
      <path d="M73.9375 177.37C77.8993 180.455 84.6445 181.747 94.7222 180.778C105.811 179.712 111.925 172.883 113.138 162.964C113.792 157.618 112.751 152.078 111.008 148.751C111.036 148.592 111.064 148.432 111.091 148.27C113.882 150.407 116.007 157.856 115.138 164.964C113.925 174.883 107.811 181.712 96.7222 182.778C85.1459 183.891 77.9669 182.021 74.3529 177.876C74.2085 177.71 74.0701 177.542 73.9375 177.37Z" fill="#28343D"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M111.092 148.27C110.2 153.535 108.95 157.229 107.292 159.383C104.339 163.223 101.791 163.804 97.9825 161.084C90.7798 155.876 84.407 154.498 79.4105 156.317C75.3091 157.81 72.4294 161.482 71.8067 165.509C71.0225 170.579 71.6578 174.784 74.3534 177.876C77.9675 182.021 85.1464 183.891 96.7227 182.778C107.812 181.712 113.925 174.883 115.138 164.964C116.008 157.856 113.882 150.407 111.092 148.27Z" fill="#2D3B45" stroke="#152633" strokeWidth="2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M67 88.3294L63.1593 93.1764H66.6981L66.8155 96.7319L59.3354 97.0338L59 93.7466L63.3774 88.3797L59.0335 88.6816L59 85.3105H66.8491L67 88.3294Z" fill="#FFC100"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M91 74.2998L85.239 81.5702H90.5472L90.7233 86.9036L79.5031 87.3564L79 82.4256L85.566 74.3753L79.0503 74.8281L79 69.7715H90.7736L91 74.2998Z" fill="#FFC100"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M68 41.6401L59.3585 52.5458H67.3208L67.5849 60.5458L50.7547 61.225L50 53.8288L59.8491 41.7533L50.0755 42.4326L50 34.8477H67.6604L68 41.6401Z" fill="#FFC100"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M110 9.81132L97.5178 25.5639H109.019L109.4 37.1195L85.0901 38.1006L84 27.4172L98.2264 9.97484L84.109 10.956L84 0H109.509L110 9.81132Z" fill="#FFC100"/>
    </SVGIcon>
  )
}

export { IconSleepingPanda }