import {SVGIcon} from '@instructure/ui-svg-images'

function IconAssessmentRowDistBenchArrow (props) {
  return (
    <SVGIcon
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      css={{position: 'absolute', top: '0px', left: '0px'}}
      {...props}
    >
      <path d="M0 0H14L0 14V0Z" />
    </SVGIcon>
  )
}

export {IconAssessmentRowDistBenchArrow}
