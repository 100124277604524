import { SVGIcon } from '@instructure/ui-svg-images'

function IconOnlineSubmissionPanda(props) {
  // TODO: Figure out why InstUI styles every path with a default fill, and
  // remove all of the `fill="none"` that we had to manually add
  return (
    <SVGIcon width="188px" height="120px" name="IconOnlineSubmissionPanda" viewBox="0 0 188 120" fill="none" {...props}>
      <path d="M156.75 89H31.25L0 104H47.5433C45.2589 105.311 44 106.746 44 108.25C44 114.463 65.4903 119.5 92 119.5C118.51 119.5 140 114.463 140 108.25C140 106.746 138.741 105.311 136.457 104H188L156.75 89Z" fill="black" fillOpacity="0.05"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M38 6H150C152.209 6 154 7.79086 154 10V86H34V10C34 7.79086 35.7909 6 38 6Z" fill="#C7CDD1" stroke="#8B969E" strokeWidth="2" strokeLinejoin="round"/>
      <path d="M40 10H148C149.105 10 150 10.8954 150 12V82H38V12C38 10.8954 38.8954 10 40 10Z" fill="white"/>
      <path d="M40 10H148C149.105 10 150 10.8954 150 12V82H38V12C38 10.8954 38.8954 10 40 10Z" stroke="#8B969E" strokeLinejoin="round" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34 86H154L174 96H14L34 86Z" fill="#8B969E" stroke="#8B969E" strokeWidth="2" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 96H174V100H14V96Z" fill="#C7CDD1" stroke="#8B969E" strokeWidth="2" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M118.118 91.7869C120.794 92.7822 122.605 100.102 120.604 105.61C118.885 110.343 114.846 113.399 108.336 113.968C100.066 114.691 94.9621 113.482 92.4306 110.842C90.5835 108.917 90.1466 106.288 90.6925 103.079C91.1829 100.196 100.49 96.2025 118.118 91.7869Z" fill="#2D3B45" />
      <path d="M90.584 106.997C90.8123 108.482 91.4034 109.771 92.4309 110.842C94.9624 113.482 100.067 114.691 108.337 113.968C114.846 113.399 118.885 110.343 120.604 105.61C122.386 100.704 121.145 94.3628 118.962 92.3303C119.653 95.0879 119.692 98.6163 118.604 101.61C116.885 106.343 112.846 109.399 106.337 109.968C98.23 110.676 93.1654 109.529 90.584 106.997Z" fill="#28343D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M118.118 91.7869C120.794 92.7822 122.605 100.102 120.604 105.61C118.885 110.343 114.846 113.399 108.336 113.968C100.066 114.691 94.9621 113.482 92.4306 110.842C90.5835 108.917 90.1466 106.288 90.6925 103.079C91.1829 100.196 100.49 96.2025 118.118 91.7869Z" stroke="#152633" strokeWidth="2" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M114.111 70.056C125.41 81.8927 130.664 91.3497 128.07 96.9199C125.289 102.892 112.908 107.19 109.806 94.9373C107.467 85.6975 109.66 74.7701 110.881 69.9529C111.967 70.0112 113.045 70.0469 114.111 70.056Z" fill="#2D3B45" />
      <path d="M125.519 84.4387C126.888 87.8262 127.11 90.6857 126.069 92.9199C123.75 97.902 114.749 101.719 110.027 95.7342C113.456 106.997 125.351 102.759 128.069 96.9199C129.497 93.8535 128.547 89.6094 125.519 84.4387Z" fill="#28343D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M114.111 70.056C125.41 81.8927 130.664 91.3497 128.07 96.9199C125.289 102.892 112.908 107.19 109.806 94.9373C107.467 85.6975 109.66 74.7701 110.881 69.9529C111.967 70.0112 113.045 70.0469 114.111 70.056Z" stroke="#152633" strokeWidth="2" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M67.3116 74.2475C62.447 73.2327 51.6638 70.414 44.3846 64.2611C34.7317 56.1022 44.0223 46.8576 50.609 46.9767C56.7524 47.0872 62.9492 55.955 68.635 71.299C68.1757 72.2614 67.7352 73.2459 67.3116 74.2475Z" fill="#2D3B45" />
      <path d="M42.3845 60.2612C49.6637 66.414 60.4469 69.2327 65.3115 70.2475C65.7351 69.246 66.1756 68.2614 66.6349 67.299C65.4792 64.1803 64.3025 61.3291 63.1093 58.7647C64.9903 62.2481 66.8381 66.4502 68.6349 71.299C68.1756 72.2614 67.7351 73.246 67.3115 74.2475C62.4469 73.2327 51.6637 70.414 44.3845 64.2612C42.0294 62.2706 40.802 60.2154 40.3877 58.2473C40.9349 58.922 41.5966 59.5952 42.3845 60.2612Z" fill="#28343D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M67.3116 74.2475C62.447 73.2327 51.6638 70.414 44.3846 64.2611C34.7316 56.1022 44.0223 46.8576 50.609 46.9767C56.7524 47.0872 62.9492 55.955 68.635 71.299C68.1757 72.2614 67.7352 73.2459 67.3116 74.2475Z" stroke="#152633" strokeWidth="2" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M63.8394 91.7874C61.7525 92.5898 59.9619 97.7113 60.6197 102.601C61.471 108.928 65.7567 113.281 73.6183 113.968C81.8885 114.691 86.9926 113.482 89.5241 110.842C91.3712 108.916 91.8081 106.288 91.2622 103.079C90.7718 100.196 81.4654 96.2028 63.8394 91.7874Z" fill="#2D3B45" />
      <path d="M60.5732 102.219C62.3722 106.554 66.3634 109.421 72.6183 109.968C80.8884 110.691 85.9925 109.482 88.5241 106.842C89.8314 105.479 90.4323 103.764 90.4783 101.74C90.9284 102.209 91.1902 102.656 91.2621 103.079C91.8081 106.288 91.3712 108.916 89.5241 110.842C86.9925 113.482 81.8884 114.691 73.6183 113.968C65.7566 113.281 61.4709 108.928 60.6197 102.601C60.6025 102.474 60.5871 102.346 60.5732 102.219Z" fill="#28343D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M63.8394 91.7874C61.7525 92.5898 59.9619 97.7113 60.6197 102.601C61.471 108.928 65.7567 113.281 73.6183 113.968C81.8885 114.691 86.9926 113.482 89.5241 110.842C91.3712 108.916 91.8081 106.288 91.2622 103.079C90.7718 100.196 81.4654 96.2028 63.8394 91.7874Z" stroke="#152633" strokeWidth="2" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M109.842 71.5193C103.081 73.771 95.3638 74.9949 87.1686 74.9949C79.8639 74.9949 73.1066 73.133 67.1413 71.4434C64.746 76.8071 62.0327 84.6798 61.4385 89.4334C59.6907 101.666 70.3237 112.387 89.2048 112.387C108.086 112.387 117.95 101.28 115.795 89.8036C114.309 81.8969 111.915 75.9234 109.842 71.5193Z" fill="white" />
      <path d="M68 106.748C72.5616 109.327 78.5333 110.868 85.6901 110.868C104.571 110.868 114.435 99.7611 112.28 88.2843C111.067 81.8253 109.247 76.6564 107.491 72.5793C108.111 72.3945 108.723 72.2014 109.328 72C111.4 76.4041 113.795 82.3776 115.28 90.2843C117.435 101.761 107.571 112.868 88.6901 112.868C79.8389 112.868 72.8002 110.512 68 106.748Z" fill="#EDEDED" />
      <path fillRule="evenodd" clipRule="evenodd" d="M109.842 71.5193C103.081 73.771 95.3638 74.9949 87.1686 74.9949C79.8639 74.9949 73.1066 73.133 67.1413 71.4434C64.746 76.8071 62.0327 84.6798 61.4385 89.4334C59.6907 101.666 70.3237 112.387 89.2048 112.387C108.086 112.387 117.95 101.28 115.795 89.8036C114.309 81.8969 111.915 75.9234 109.842 71.5193Z" stroke="#152633" strokeWidth="2" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M84.7936 96.6976C85.0949 95.885 85.877 95.3059 86.7944 95.3059C87.7119 95.3059 88.494 95.885 88.7953 96.6976C89.6128 96.4284 90.5471 96.6732 91.1209 97.3818C91.7018 98.0991 91.7409 99.079 91.2903 99.8263C92.0172 100.302 92.4075 101.196 92.2007 102.092C91.9948 102.984 91.2572 103.615 90.3999 103.728C90.4806 104.587 90.0318 105.445 89.2106 105.845C88.3826 106.249 87.4213 106.068 86.7944 105.464C86.1676 106.068 85.2063 106.249 84.3783 105.845C83.5571 105.445 83.1083 104.587 83.189 103.728C82.3317 103.615 81.5941 102.984 81.3882 102.092C81.1814 101.196 81.5717 100.302 82.2985 99.8263C81.848 99.079 81.8871 98.0991 82.468 97.3818C83.0418 96.6732 83.9761 96.4284 84.7936 96.6976Z" fill="#2D3B45" stroke="#152633" strokeWidth="2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M50.0528 35.8605C48.7411 35.8024 47.2726 35.5677 45.6942 35.0349C39.6244 32.9852 39.3345 27.6997 39.0776 26.0186C38.8206 24.3376 38.7855 15.2633 45.9948 16.5434C50.6508 17.3701 54.1318 19.2601 57.3428 20.8196C53.9924 25.3753 51.5248 30.5146 50.0528 35.8605Z" fill="#2D3B45" />
      <path d="M43.694 31.0349C45.2725 31.5677 46.741 31.8023 48.0526 31.8605C49.2846 27.3864 51.2139 23.057 53.7744 19.0934C54.6472 19.4949 55.4835 19.9067 56.2973 20.3073C56.6496 20.4807 56.9977 20.6521 57.3426 20.8196C53.9922 25.3753 51.5247 30.5146 50.0526 35.8605C48.741 35.8023 47.2725 35.5677 45.694 35.0349C41.4438 33.5996 40.0276 30.5777 39.4722 28.3178C40.4182 29.4041 41.7639 30.3831 43.694 31.0349Z" fill="#28343D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M50.0528 35.8605C48.7411 35.8024 47.2726 35.5677 45.6942 35.0349C39.6244 32.9852 39.3345 27.6997 39.0776 26.0186C38.8206 24.3376 38.7855 15.2633 45.9948 16.5434C50.6508 17.3701 54.1318 19.2601 57.3428 20.8196C53.9924 25.3753 51.5248 30.5146 50.0528 35.8605Z" stroke="#152633" strokeWidth="2" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.3721 44.1537C47.3721 64.177 64.5826 74.8791 88.2424 74.8791C111.902 74.8791 131.082 62.796 131.082 44.8441C131.082 22.5364 111.902 1 88.2424 1C64.5826 1 47.3721 23.0947 47.3721 44.1537Z" fill="white" />
      <path d="M58.3154 66.5716C65.3562 70.7353 74.6535 72.879 85.2427 72.879C108.903 72.879 128.083 60.796 128.083 42.8441C128.083 28.9139 120.603 15.2845 109.225 7.01221C122.271 14.9575 131.083 29.7236 131.083 44.8441C131.083 62.796 111.903 74.879 88.2427 74.879C76.0722 74.879 65.6082 72.0473 58.3154 66.5716Z" fill="#EDEDED" />
      <path fillRule="evenodd" clipRule="evenodd" d="M47.3721 44.1537C47.3721 64.177 64.5826 74.8791 88.2424 74.8791C111.902 74.8791 131.082 62.796 131.082 44.8441C131.082 22.5364 111.902 1 88.2424 1C64.5826 1 47.3721 23.0947 47.3721 44.1537Z" stroke="#152633" strokeWidth="2" fill="none" />
      <path fillRule="evenodd" clipRule="evenodd" d="M119.84 37.2938C121.413 36.6409 123.099 35.7033 124.787 34.3534C131.277 29.1615 129.979 20.5082 129.546 18.3449C129.113 16.1816 126.517 5.79775 118.297 10.557C112.988 13.6307 109.929 17.3912 106.706 20.7171C112.054 25.2626 118.297 33.0166 119.84 37.2938Z" fill="#2D3B45" />
      <path d="M108.747 18.5637C108.597 18.7231 108.448 18.882 108.299 19.0405C107.765 19.6078 107.238 20.1684 106.706 20.7172C112.054 25.2626 118.297 33.0166 119.84 37.2938C121.413 36.641 123.099 35.7034 124.787 34.3535C129.919 30.2476 130.181 23.9771 129.852 20.4635C123 42.0001 115.573 25.091 108.747 18.5637Z" fill="#28343D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M119.84 37.2938C121.413 36.6409 123.099 35.7033 124.787 34.3534C131.277 29.1615 129.979 20.5082 129.546 18.3449C129.113 16.1816 126.517 5.79775 118.297 10.557C112.988 13.6307 109.929 17.3912 106.706 20.7171C112.054 25.2625 118.297 33.0166 119.84 37.2938Z" stroke="#152633" strokeWidth="2" fill="none" />
    </SVGIcon>
  )
}

export { IconOnlineSubmissionPanda }
