import { SVGIcon } from '@instructure/ui-svg-images'
import { forwardRef } from 'react'

const IconApprovedStar = forwardRef((props, ref) => {
  return (
    <SVGIcon name={'IconApprovedStar'} viewBox="0 0 18 18" ref={ref} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.140625L6.499 2.40963L3.126 2.27863L2.669 5.62463L0 7.69263L1.8 10.5486L1.085 13.8486L4.3 14.8796L5.874 17.8666L9 16.5896L12.126 17.8666L13.7 14.8796L16.915 13.8486L16.2 10.5486L18 7.69263L15.331 5.62463L14.874 2.27863L11.5 2.40963L9 0.140625ZM12.307 5.91963L13.693 7.36163L8.068 12.7736L4.292 8.99763L5.706 7.58363L8.096 9.97263L12.307 5.91963Z"
      />
    </SVGIcon>
  )
})

export {IconApprovedStar}
